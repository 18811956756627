import useSWR from 'swr';
import { useUser } from '@auth0/nextjs-auth0';
import axiosWithMjrAccessToken from 'utils/axiosWithMjrAccessToken';
import axios from 'axios';

export const useMjrApiUser = () => {
  const { user: session } = useUser();

  if (typeof window === 'undefined') {
    const e = "usemjrapiuser called on server side";
    console.warn(e);
  }

  const url = `${process.env.NEXT_PUBLIC_PROXY_MJR_API_URL}/api/customer/get`;

  return useSWR(
    // if we pass null into the cache key useSWR will not fetch
    // https://swr.vercel.app/docs/conditional-fetching
    () => typeof window !== 'undefined' && session ? `${url}|${session.sub}` : null,
    () => axiosWithMjrAccessToken.get(url).then((res) => res.data)
  );
};

export const useContent = () => useSWR(
  '/api/get-content',
  (url) => axios.get(url).then((res) => res.data),
);
