import { v4 as uuidv4 } from 'uuid';
import applicationInsights from "./applicationInsights";

const axios = require('axios');

const axiosWithMjrAccessToken = axios.create();

// Request interceptor for API calls
axiosWithMjrAccessToken.interceptors.request.use(
  async (config) => {
    config.headers['Request-Id'] = uuidv4();
    const accessTokenResult = await axios.get('/api/get-access-token');
    config.headers.Authorization = `Bearer ${accessTokenResult.data.accessToken}`;
    config.headers['X-Start-Timestamp'] = new Date().toISOString();
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosWithMjrAccessToken.interceptors.response.use(response => {
    // Track successful response
    applicationInsights.trackDependencyData({
        id: response.config.url,
        name: response.config.method.toUpperCase(),
        duration: new Date().toISOString() - new Date(response.config.headers['X-Start-Timestamp']),
        success: true,
        resultCode: response.status,
        data: response.config.url,
        dependencyTypeName: 'Ajax'
    });
    return response;
}, error => {
    // Track errors
    if (error.config) {
        // Track failed response
        applicationInsights.trackDependencyData({
            id: error.config.url,
            name: error.config.method.toUpperCase(),
            duration: new Date().toISOString() - new Date(error.config.headers['X-Start-Timestamp']),
            success: false,
            resultCode: error.response ? error.response.status : 0,
            data: error.config.url,
            dependencyTypeName: 'Ajax'
        });
    } else {
        // Track any other errors (network issues, etc.)
        applicationInsights.trackException({ exception: error });
    }
    return Promise.reject(error);
});

export default axiosWithMjrAccessToken;
